import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { RichText as PrismicRichText } from "prismic-reactjs";

import { linkResolver, serializer } from "src/utils";

import {
  Button,
  PrismicLink,
  PageHeaderV2,
  RichText,
  SectionWrapper,
  SEO,
  FlexCol,
} from "src/components";

import Collage from "../../_components/_collageSlice";

const ArtistsPage = ({ setInvert, setInvertLogo, preview }) => (
  <StaticQuery
    query={graphql`
      {
        getInvolved: prismicGetInvolved {
          dataString
        }
      }
    `}
    render={(data) => {
      let page = JSON.parse(data.getInvolved.dataString);
      if (preview) {
        page = preview;
      }
      return (
        <>
          <SEO
            image={
              page.opengraph_image &&
              page.opengraph_image.url &&
              page.opengraph_image.url
            }
            imageAlt={
              page.opengraph_image &&
              page.opengraph_image.alt &&
              page.opengraph_image.alt
            }
            title={"Artists"}
            description={
              page.artists_description &&
              PrismicRichText.asText(page.artists_description)
            }
          />
          <PageHeaderV2
            heading={`Artists`}
            color="brand.altPrimary"
            invert={true}
            image={page.artists_image}
            setInvertLogo={setInvertLogo}
            setInvert={setInvert}
          />
          {page.artists_description && page.artists_description[0] && (
            <SectionWrapper maxWidth={0} py={[8, 9]} bg="bg.default">
              <RichText>
                {PrismicRichText.render(
                  page.artists_description,
                  linkResolver,
                  serializer
                )}
              </RichText>
            </SectionWrapper>
          )}
          {page.submission_button_text &&
            page.submission_button_link &&
            page.submission_button_link.link_type && (
              <SectionWrapper maxWidth={0} pb={[8, 9]} bg="bg.default">
                <Button
                  as={PrismicLink}
                  link={page.submission_button_link}
                  children={page.submission_button_text}
                  variant="primary"
                />
              </SectionWrapper>
            )}
          {page.artists_collage &&
            page.artists_collage[0] &&
            page.artists_collage[0].image &&
            page.artists_collage[0].image.url && (
              <Collage items={page.artists_collage} />
            )}
        </>
      );
    }}
  />
);

export default ArtistsPage;
